<template>
  <ul class="accordion">
    <li
      class="accordion__item"
      v-for="(accordion, index) in accordionList"
      :key="index"
    >
      <AccordionItem>
        <h3 slot="header">{{ accordion.title }}</h3>
        <span v-html="accordion.text"></span>
      </AccordionItem>
    </li>
  </ul>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import AccordionItem from "@/components/Accordion/AccordionItem.vue";

@Component({
  components: {
    AccordionItem,
  },
})
export default class AccordionList extends Vue {
  get accordionList() {
    return this.$router.history.current.name === "user"
      ? [
          {
            id: 1,
            title: "Как поможет приложение?",
            text: "Сон наиболее недооцененный ресурс управления жизнью человека. Мы хотим исправить это и помогаем превратить сон в полезную привычку через игровые механики.",
          },
          {
            id: 2,
            title: "Кому не подходит?",
            text: "Если у вас диагностированы бессонница или нарушения сна связаны с психологическими расстройствами - то здесь не до веселья. Сразу обратитесь к специалистам в клинику и лучше с этим не затягивать.",
          },
          {
            id: 3,
            title: "Как начисляются подушки?",
            text: `1. Мы собираем информацию о вашем сне с помощью устройств, отслеживающих сон, которые вы добавили в приложение. За каждый час сна мы начисляем вам подушки, а за качественный сон - втрое больше. 
      <br></br>2. Мы предлагаем награду для пользователей, которые прочитали новую статью об улучшении и успешно ответили на связанный вопрос.
      <br></br>3. Начисляем подушки тем, кто ведет дневник сна 
      <br></br>4. А еще можно приглашать друзей и получать за них подушки`,
          },
          {
            id: 4,
            title: "Как участвовать в розыгрышах?",
            text: "Полученные подушки можно обменять на билет в одном из розыгрышей в разделе Розыгрыши",
          },
          {
            id: 5,
            title: "Какие трекеры сна доступны?",
            text: `Доступные трекеры для подключения: <br></br>
<uL>
<li> - oura </li>
<li> - garmin </li>
<li> - apple watch </li>
<li> - samsung watch </li>
<li> - xiaomi mi band 7 </li>
</ul>
`,
          },
          {
            id: 6,
            title: "Почему важно отслеживать сон?",
            text: "Чтобы улучшать сон нужно его начать контролировать, а значит измерять.",
          },
        ]
      : [
          {
            id: 1,
            title: "Сколько занимает пилот по времени?",
            text: "Пилот длится 4 недели, после чего подводим итоги.",
          },
          {
            id: 2,
            title: "Как проходит пилот?",
            text: `1. Проводим для сотрудников лекцию по сну.
<br></br>2. Создаем аккаунты сотрудникам.
<br></br>3. Создаем корпоративный турнир.
<br></br>4. Предоставляем доступ к библиотеке сна.
<br></br>5. Пользователи получают “подушки” (внутренняя валюта) за сон, турниры и задания.
<br></br>6. Полученные “подушки” обменивают на билеты в розыгрышах.
<br></br>7. Проводим финальный розыгрыш среди участников.`,
          },
          {
            id: 3,
            title: "Какие девайсы нужны сотрудникам для участия в пилоте:",
            text: "Самые популярные: apple watch, samsung watch, oura, garmin, xiaomi",
          },
          {
            id: 4,
            title: "Как провести пилот со sleeptery?",
            text: "Оставьте заявку в форме и мы с вами свяжемся днем в рабочее время. Ночью не будем - мы сами спим.",
          },
        ];
  }
}
</script>

<style lang="scss" scoped>
.accordion {
  &__item {
    border-bottom: 1px solid #a0a0a0;

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
