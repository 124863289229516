var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form"},[_c('FormulateForm',{staticClass:"form__main",attrs:{"autocomplete":"off","name":"question"},on:{"submit":_vm.submitForm}},[_c('div',[_c('FormulateInput',{staticClass:"form__fio",attrs:{"name":"fio","label":"Ваше имя","type":"text","validation":"required","validation-messages":{
          required: 'Это поле обязательно',
        },"autocomplete":"off"},model:{value:(_vm.formMailings.fio),callback:function ($$v) {_vm.$set(_vm.formMailings, "fio", $$v)},expression:"formMailings.fio"}}),(_vm.modal)?_c('FormulateInput',{staticClass:"form__email",attrs:{"name":"company","type":"text","label":"Компания","validation":"required","validation-messages":{
          required: 'Это поле обязательно',
        },"autocomplete":"off"},model:{value:(_vm.formMailings.company),callback:function ($$v) {_vm.$set(_vm.formMailings, "company", $$v)},expression:"formMailings.company"}}):_vm._e(),(_vm.modal)?_c('FormulateInput',{directives:[{name:"mask",rawName:"v-mask",value:(`+7(###) ###-##-##`),expression:"`+7(###) ###-##-##`"}],staticClass:"form__email",attrs:{"name":"phone","type":"text","label":"Телефон","validation":"^required|min:17","validation-messages":{
          required: 'Это поле обязательно',
          min: 'Введите верный телефон',
        },"autocomplete":"off"},model:{value:(_vm.formMailings.phone),callback:function ($$v) {_vm.$set(_vm.formMailings, "phone", $$v)},expression:"formMailings.phone"}}):_vm._e(),_c('FormulateInput',{staticClass:"form__email",attrs:{"name":"email","type":"text","label":"Email","validation":"^required|email","validation-messages":{
          required: 'Это поле обязательно',
          email: 'Введите реальный email',
        },"autocomplete":"off"},model:{value:(_vm.formMailings.email),callback:function ($$v) {_vm.$set(_vm.formMailings, "email", $$v)},expression:"formMailings.email"}}),(!_vm.modal)?_c('FormulateInput',{staticClass:"form__comment",attrs:{"name":"comment","type":"textarea","label":_vm.modal ? 'Сообщение' : 'Вопрос...',"validation":_vm.modal ? '' : 'required',"validation-messages":{
          required: 'Это поле обязательно',
        },"autocomplete":"off"},model:{value:(_vm.formMailings.comment),callback:function ($$v) {_vm.$set(_vm.formMailings, "comment", $$v)},expression:"formMailings.comment"}}):_vm._e(),_c('FormulateInput',{staticClass:"form__checkbox",attrs:{"name":"checkbox","type":"checkbox","label":_vm.labelCheckbox,"validation":"required","validation-messages":{
          required: 'Ваше согласие обязательно',
        }},scopedSlots:_vm._u([{key:"label",fn:function({ label, id }){return [_c('label',{staticClass:"formulate-input-label",attrs:{"for":id},domProps:{"innerHTML":_vm._s(label)}})]}}]),model:{value:(_vm.formMailings.checkbox),callback:function ($$v) {_vm.$set(_vm.formMailings, "checkbox", $$v)},expression:"formMailings.checkbox"}})],1),(!_vm.sendingBtn)?_c('span',[_vm._t("btn")],2):_vm._e(),(_vm.sendingBtn)?_c('button',{staticClass:"form__btn",staticStyle:{"opacity":"0.5"},attrs:{"disabled":""}},[_vm._v(" Отправка... ")]):_vm._e()]),(_vm.sended && !_vm.modal)?_c('div',{staticClass:"form__success",on:{"click":function($event){_vm.sended = false}}},[_c('p',{staticClass:"form__success-text"},[_vm._v("Вопрос отправлен!")]),_c('button',{staticClass:"form__btn",on:{"click":function($event){_vm.sended = false}}},[_vm._v("Хорошо")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }