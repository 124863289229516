var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`main main-${_vm.$router.history.current.name}`},[_vm._m(0),(_vm.$router.history.current.name === 'user')?_c('lottie',{ref:"mainAnimation",staticClass:"main__animation",attrs:{"options":_vm.lottieOptionsUser,"height":400,"width":400}}):_vm._e(),(_vm.$router.history.current.name === 'business')?_c('lottie',{ref:"mainAnimation",staticClass:"main__animation main__animation-business",attrs:{"options":_vm.mobile ? _vm.lottieOptionsBusiness.mobile : _vm.lottieOptionsBusiness.desktop,"height":400,"width":400}}):_vm._e(),(_vm.modal)?_c('Modal',{attrs:{"modal":!!_vm.page},on:{"switchModal":_vm.switchModal}}):_vm._e(),_c('FormModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.formModal),expression:"formModal"}],attrs:{"openModal":_vm.formModal},on:{"switchFormModal":_vm.switchFormModal}}),(_vm.telegrammModal)?_c('TelegrammModal',{attrs:{"btnName":_vm.btnName},on:{"switchTelegrammModal":_vm.switchTelegrammModal}}):_vm._e(),_c('div',{staticClass:"main__wrapper"},[_c('nav',{staticClass:"main__nav"},[_c('a',{staticClass:"main__link",attrs:{"href":"/"}},[_c('i',{class:`icon-logo ${
            _vm.$router.history.current.name === 'user' ||
            _vm.$router.history.current.name === 'fansleep'
              ? ''
              : 'icon-logo-business'
          }`}),_c('img',{attrs:{"src":require("@/assets/img/I-1.png")}}),(_vm.$router.history.current.name == 'fansleep')?_c('span',{staticClass:"main__fansleep"},[_vm._v("ФАНАТ СНА")]):_vm._e()]),(_vm.$router.history.current.name !== 'privacy')?_c('div',{staticClass:"main__container"},[(
            _vm.$router.history.current.name === 'user' ||
            _vm.$router.history.current.name === 'fansleep'
          )?_c('a',{staticClass:"main__rout",attrs:{"href":"/business"}},[_vm._v(_vm._s(_vm.$router.history.current.name === "fansleep" ? "Для бизнеса" : "Компаниям"))]):_c('a',{staticClass:"main__rout",attrs:{"href":"/"}},[_vm._v("Пользователям")]),(_vm.$router.history.current.name === 'user')?_c('button',{staticClass:"main__btn",on:{"click":_vm.switchFormModal}},[_vm._v(" Начать ")]):_vm._e(),(_vm.$router.history.current.name === 'business')?_c('button',{staticClass:"main__btn",on:{"click":_vm.switchModal}},[_vm._v(" Подключить ")]):_vm._e(),(_vm.$router.history.current.name === 'fansleep')?_c('a',{staticClass:"main__btn",attrs:{"href":"#footer"}},[_vm._v(" Скачать ")]):_vm._e()]):_vm._e()]),_c('router-view',{on:{"switchModal":_vm.switchModal,"switchFormModal":_vm.switchFormModal,"switchTelegrammModal":_vm.switchTelegrammModal}}),_c('Footer',{attrs:{"id":"footer"}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main__background"},[_c('div',{staticClass:"main__background-1"},[_c('div',{staticClass:"main__background-1-point"})]),_c('div',{staticClass:"main__background-2"},[_c('div',{staticClass:"main__background-2-point"})])])
}]

export { render, staticRenderFns }